<template>
	<section class="container">
		<b-card title="Virksomhedsadmin">
			<div class="row mt-5">
				<div class="col-auto mx-auto">
					<router-link :to="{ name: 'CompanyAdminAdd', params: { companyId: companyId } }">
						<b-button variant="success" class="mb-3">Opret ny virksomhedadmin</b-button>
					</router-link>
				</div>
				<div class="col-auto mx-auto">
					<router-link :to="{ name: 'CompanyAdminSet', params: { companyId: companyId } }">
						<b-button variant="primary" class="mb-3">Tilføj eksisterende bruger</b-button>
					</router-link>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<BaseTable :items="companyAdmins" :fields="fields" :isBusy="isBusy">
						<template v-slot:cell(firstName)="data">
							<router-link
								:to="{
									name: 'User',
									params: { id: data.item.id },
								}"
							>
								{{ getFullName(data.item) }}
							</router-link>
						</template>
						<template v-slot:cell(email)="data">
							{{ data.item.email }}
						</template>
						<template v-slot:cell(remove)="data">
							<BaseActionButton
								v-bind:clicked="clicked"
								v-bind:disabled="clicked"
								v-on:on-click="removeAdmin(data.item.id)"
								type="button"
								id="signupButton"
								class="btn-delete"
							>
								Fjern admin
							</BaseActionButton>
						</template>
					</BaseTable>
					<br />
					<div v-show="$can('read', 'Company', 'CommentEmployee')">
						<label>
							<b>Kommentar omkring virksomhedsadmin</b>
						</label>
						<InputTextArea v-model="company.commentEmployee"></InputTextArea>
					</div>
					<div class="row">
						<div class="col-12 mt-5">
							<BaseActionButton
								class="btn-update"
								v-bind:clicked="clicked"
								v-bind:disabled="clicked"
								@on-click="onSubmit"
								v-if="$can('update', 'Company', 'CompanyInfo')"
							>
								{{ $t('global.update') }}
							</BaseActionButton>
						</div>
					</div>
				</div>
			</div>
		</b-card>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
import InputTextArea from '../../../global/atomComponents/InputTextArea.vue'

export default {
	name: 'CompanyAdminLis',
	mixins: [dateMixin],
	components: {
		BaseTable,
		InputTextArea,
	},
	data() {
		return {
			calucationType: ' kr.',
			clicked: false,
			fields: [
				{
					key: 'firstName',
					label: i18n.t('employeeList.table.name'),
					sortable: true,
				},
				{
					key: 'email',
					label: 'email',
					sortable: true,
				},
				{
					key: 'remove',
					label: 'Slet',
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			var data = this.company
			var id = this.id
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompany', {
					data,
					id,
				})
				this.clicked = false
				this.toast('Success', 'Et selskab nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
		getUser(employeeId) {
			var employee = this.users.find((item) => {
				return item.id == employeeId
			})
			return employee
		},
		getEmail(employee) {
			return employee.email ?? ' '
		},
		getFullName(employee) {
			return employee.firstName + ' ' + employee.lastName ?? ' '
		},
		async removeAdmin(id) {
			this.clicked = true
			try {
				console.log('Removing a companyAdmin', id)
				await this.$store.dispatch('companyVuex/removeCompanyAdmin', {
					uid: id,
					companyId: this.companyId,
				})
				this.toast('Success', 'En virksomhedsadmin nu fjernet', true)
				this.$router.push({
					name: 'CompanyAdminList',
					params: { companyId: this.companyId },
				})
			} catch (err) {
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
	},
	computed: {
		companyAdmins() {
			return (
				this.company?.companyAdmins
					?.map((employeeId) => {
						return this.getUser(employeeId)
					})
					.sort(function (a, b) {
						if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
							return -1
						}
						if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
							return 1
						}
						return 0
					}) || []
			)
		},
		users() {
			return this.$store.getters['companyVuex/companyAdmins']
		},
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['companyVuex/isReady']
		},
		id() {
			return this.$route.params.companyId
		},
	},
}
</script>

<style lang="scss"></style>
